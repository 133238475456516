.dashboard-wrapper {
    margin-top: 3rem;
    padding: 1rem;
}

.dashboard-wrapper .row-header {    
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    /* border-bottom: solid 1px #ccc; */
}

.dashboard-wrapper .row-top {    
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.dashboard-wrapper .row-header .btn-success *{    
    margin-right: 0.5rem;
}

.chart-legend {
    font-size: 0.9rem;
    font-weight: 400;
}

g.highcharts-legend-item.highcharts-column-series {
    display: none;
}

.card.class-card {
    background-color: #F2F2F4;
}

.card.class-card .card-btn{
    background-color: #89C440;
    border-color: #89C440;
    color: white;
}

header nav .nav-link {
    border: solid 1px #C2C4C5;
    border-radius: 8px;
}

header nav .nav-link:hover {
    background-color: #C2C4C5;
    color: white !important;
}

.btn.btn-success {
    background-color: #89C440;
    border-color: #89C440;
}

.btn.btn-success:hover {
    background-color: #fff;
    border-color: #89C440;
    color: #89C440;
}

.course-bottom {
    text-align: right;
    margin-top: 10px;
}
.loading {

    background-color: white;
    opacity: 0.9;
    border-radius: 10px;
    text-align: center;
    height: 190px;

}
.loading .spinner-border {
    margin-top: 70px;
}