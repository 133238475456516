.table-container {
    overflow: auto;
}

.table-container td,
.table-container thead th {
    text-align: center;
}

.table-container input {
    width: 60px;
}

.table-container .row-green {
    background-color: #A6D785;
    font-weight: 500;
}

.table-container .row-yellow {
    background-color: #ffffcc;
    font-weight: 500;
}

.table-container .bordered-name {
    padding: 5px;
    text-align: left;
}

.table-container .bordered-name td {
    text-align: left;
}

.charts-component-wrapper,
.carbon-wrapper {
    padding: 30px 0;
}

.charts-component-wrapper .nav-tabs > a {
    width: 33.33%;
    text-align: center;
}